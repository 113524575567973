import { useCallback } from 'react';

import { ForceDecimal, formatPriceValue, SwapStatusType, SwapType } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import getPairsByType from '@Components/trade/buySell/helpers/getPairsByType';
import { useBuySellStore } from '@Components/trade/buySell/store';
import { useSwapStore } from '@Store/swapStore';

/**
 * Hook for managing swap state.
 */
const useSwapStateHook = () => {
  const { i18n } = useTranslation();
  const { buySellType } = useBuySellStore();
  const { swapOffer, setSwapOffer } = useSwapStore((state) => ({
    swapOffer: state.swapOffer,
    setSwapOffer: state.setSwapOffer,
  }));

  const isOfferFetching =
    swapOffer?.status === 'requested' || swapOffer?.status === 'pending' || swapOffer?.status === 'initiated';

  const isOfferExecuting = swapOffer?.status === 'pending_fill' || swapOffer?.status === 'completed';

  const changeSwapStatus = useCallback(
    (status: SwapStatusType) => {
      setSwapOffer((prev) => ({ ...prev, status: status }) as SwapType);
    },
    [setSwapOffer],
  );
  // value: buySellType === 'buy' ? swapOffer?.offer_price : BigNumber(1).dividedBy(swapOffer?.offer_price||"0").toString(),
  const terminateRequest = useCallback(() => {
    setSwapOffer(undefined);
  }, [setSwapOffer]);

  const fromAmount = formatPriceValue({ value: swapOffer?.from_amount });
  const offerAmount = formatPriceValue({ value: swapOffer?.offer_amount });
  const offerPrice = formatPriceValue({
    value: swapOffer?.offer_price,
  });

  const commission = formatPriceValue({
    value: BigNumber(swapOffer?.commission || 0)
      .minus(swapOffer?.commission_discount || 0)
      .toString(),
    locale: i18n.language,
    forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
    decimal: 2,
  });

  const hasDiscount = swapOffer && Number(swapOffer.commission_discount) > 0;
  const hasPartialDiscount = hasDiscount && swapOffer.commission_discount !== swapOffer.commission;

  const commissionPercentage = BigNumber(swapOffer?.commission_rate || '0')
    .multipliedBy(100)
    .toString();
  // Sometimes offer might fail and we might get invalid offer price.
  const isOfferPriceValid = BigNumber(offerPrice).isGreaterThan(0);
  const commissionAsset = swapOffer?.commission_asset;
  const fromAsset = swapOffer?.from_asset;
  const toAsset = swapOffer?.to_asset;

  const [baseAsset, quoteAsset] = getPairsByType(buySellType, `${fromAsset}_${toAsset}`);

  // There are three case for commission: Free (Zero), dash (-, when the offer fails) and real value.
  // If commission is zero, return null and handle it inside commission component by replacing null with free text.
  const commissionText = BigNumber(commission).isZero() ? undefined : `${commission} ${commissionAsset || ''}`;

  return {
    swapOffer,
    setSwapOffer,
    // Return dash (-) as the price is fetching.
    // Why? Because when the price is fetching, the commission is also zero. We don't want to mix it with free commission.
    commission: { value: isOfferPriceValid ? commissionText : '-', rate: commissionPercentage, hasPartialDiscount },
    fromAmount,
    offerAmount,
    changeSwapStatus,
    offerPrice,
    convertedOfferPrice: buySellType === 'buy' ? offerPrice : BigNumber(1).div(offerPrice).toString(),
    fromAsset,
    toAsset,
    isOfferExecuting,
    isOfferFetching,
    isOfferOpen: swapOffer?.status === 'open',
    isOfferTimeout: swapOffer?.status === 'timeout',
    isOfferCanceled: swapOffer?.status === 'canceled',
    isOfferCompleted: swapOffer?.status === 'completed',
    isOfferPendingFill: swapOffer?.status === 'pending_fill',
    isOfferRequested: swapOffer?.status === 'requested',
    isOfferFailed: swapOffer?.status === 'canceled' && !isOfferPriceValid,
    offerTtl: swapOffer?.offer_ttl || 0,
    offerId: swapOffer?.id,
    terminateRequest,
    offerStatus: swapOffer?.status,
    buySellType: buySellType,
    baseAsset,
    quoteAsset,
  };
};
export default useSwapStateHook;
