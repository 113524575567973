import React, { memo } from 'react';

import { Button, Flex, Grid, Meta, Panel, Typography } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconCustom } from '@Components/icon/CustomIcon';

import KYCLevelInfoCard from './components/KYCLevelInfoCard';

const { Text } = Typography;

function AccountCards() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid col={{ sm: 1, md: 1, lg: 2 }} gap="4xl">
      <Panel size="2xl" border="neutral-400">
        <Flex direction="col" gap={{ xs: 'lg' }} items="start">
          <Meta
            items="start"
            direction={{ xs: 'col', sm: 'row' }}
            position={{ xs: 'left', sm: 'right' }}
            justify="between"
            minWidth="full"
            gap="sm"
            extra={<IconCustom type="security" />}
            title={
              <Text heading size="xl" weight="bold">
                {t('account.cards.security-settings.title')}
              </Text>
            }
            description={
              <Text size="sm" weight="regular">
                {t('account.cards.security-settings.description')}
              </Text>
            }
          />
          <Button size="md" variant="filled" onClick={() => navigate('/account/security-settings')}>
            {t('account.cards.security-settings.security-tips')}
          </Button>
        </Flex>
      </Panel>
      <KYCLevelInfoCard />
      <Panel size="2xl" border="neutral-400">
        <Flex direction="col" gap={{ xs: 'lg' }} items="start">
          <Meta
            items="start"
            direction={{ xs: 'col', sm: 'row' }}
            position={{ xs: 'left', sm: 'right' }}
            justify="between"
            minWidth="full"
            gap="sm"
            extra={<IconCustom type="referral" />}
            title={
              <Text heading size="xl" weight="bold">
                {t('account.cards.referral.title')}
              </Text>
            }
            description={
              <Text size="sm" weight="regular">
                {t('account.cards.referral.desc')}
              </Text>
            }
          />

          <Button size="md" variant="outline" onClick={() => navigate('/referral')}>
            {t('account.cards.referral.invite')}
          </Button>
        </Flex>
      </Panel>
      <Panel size="2xl" border="neutral-400">
        <Flex direction="col" gap={{ xs: 'lg' }} items="start">
          <Meta
            items="start"
            direction={{ xs: 'col', sm: 'row' }}
            position={{ xs: 'left', sm: 'right' }}
            justify="between"
            minWidth="full"
            gap="sm"
            extra={<IconCustom type="preferences" />}
            title={
              <Text heading size="xl" weight="bold">
                {t('account.cards.preferences.title')}
              </Text>
            }
            description={
              <Text size="sm" weight="regular">
                {t('account.cards.preferences.desc')}
              </Text>
            }
          />

          <Button size="md" variant="outline" onClick={() => navigate('/account/preferences')}>
            {t('account.cards.preferences.manage')}
          </Button>
        </Flex>
      </Panel>
    </Grid>
  );
}

export default memo(AccountCards);
