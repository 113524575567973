import React from 'react';

import { Block, Hidden, Icon, Layout, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import TwitterShareButton from '@Modules/market/detail/components/components/TwitterShareButton';
import useAssetDetail from '@Modules/market/detail/hooks/useAssetDetail';

import WatchlistStar from './components/components/WatchlistStar';

const { Hero } = Layout;

const Header = () => {
  const { t } = useTranslation();

  const { assetData: result, base, quote } = useAssetDetail();
  const navigate = useNavigate();

  return (
    <>
      <Hero view="strip">
        <Block row justify="between" items="center">
          <Hidden show={{ sm: true, md: true, lg: true, xl: true }}>
            <Text color="white" weight="medium" my="md" onClick={() => navigate('/market')}>
              <Icon type="o:arrow-left" size="md" /> {t('market.all-markets')}
            </Text>
          </Hidden>

          {result && (
            <>
              <Text heading size="xl" color="white" weight="bold" my="md">
                {`${result.name} (${result.symbol})`}
              </Text>

              <Block row gap="md">
                <WatchlistStar baseAsset={base} quoteAsset={quote} />
                <TwitterShareButton name={result.name} symbol={result.symbol} quoteAsset={quote} />
              </Block>
            </>
          )}
        </Block>
      </Hero>
    </>
  );
};

export default Header;
