import React, { forwardRef, LiHTMLAttributes, ReactNode, Ref, useRef } from 'react';

import { clsx } from 'clsx';
import { useNavigate } from 'react-router-dom';
import ShortUniqueId from 'short-unique-id';

const uid = new ShortUniqueId({ length: 10 });

import { useStore } from './store';
import { BaseComponentType } from '../types';

/**
 * Props for the `MenuItem` component.
 */
export type MenuItemProps = LiHTMLAttributes<HTMLLIElement> &
  BaseComponentType & {
    /** Indicates whether the menu item is disabled. */
    disabled?: boolean;
    /** The URL to navigate to when the menu item is clicked, or a function to be executed. */
    href?: string | (() => void);
    /** The icon to be displayed next to the menu item, or a function returning the icon based on its active state. */
    icon?: React.ReactNode | React.ReactElement | ((active: boolean) => React.ReactNode);
    /** The content of the menu item, or a function returning the content based on its active state. */
    children: React.ReactNode | React.ReactElement | ((active: boolean) => React.ReactNode);
    /** The index of the menu item. */
    index?: number;
    /** Additional class name for the menu item. */
    className?: string;
  };

/**
 * The `MenuItem` component is used to create individual items within a `Menu` component.
 * @see `Menu`
 *
 * @example
 * const data = [
 *   {
 *     id: 1,
 *     name: 'Item 1',
 *   },
 *   {
 *     id: 2,
 *     name: 'Item 2',
 *   },
 *   {
 *     id: 3,
 *     name: 'Item 3',
 *   },
 * ];
 *
 * <Menu data={data} renderItem={(item) => <MenuItem key={item.id}>{item.name}</MenuItem>} />
 */
const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  ({ children, index, className, icon, disabled, href, testId, ...props }: MenuItemProps, ref: Ref<HTMLLIElement>) => {
    const { setSelectedItem, selectedItem } = useStore();

    const uniqueId = useRef<string>(uid.rnd()).current;
    const navigate = useNavigate();

    const classes = clsx('snap-end', className, {
      disabled: disabled,
    });

    const onClick = () => {
      setSelectedItem(uniqueId);

      if (typeof href === 'string') {
        navigate(href);
      } else if (typeof href === 'function') {
        href();
      }
    };

    const active = selectedItem === uniqueId;

    const iconNode = typeof icon == 'function' ? icon(active) : icon;
    const childrenNode = typeof children == 'function' ? children(active) : children;

    return (
      <li
        data-index={index}
        data-id={uniqueId}
        onClick={onClick}
        role="menuitem"
        className={classes}
        {...props}
        ref={ref}
        data-testid={testId ?? 'menu-item'}
      >
        <span>
          {icon && <span className="menu-item-icon">{iconNode}</span>}
          <span className="menu-item">{childrenNode}</span>
        </span>
      </li>
    );
  },
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
