import React from 'react';

import { clsx } from 'clsx';

import { TTableProps } from './table';
import { Display } from '../display';

type Props<TEntry> = {
  /** Additional styling options for the table body. */
  bodyStyle: TTableProps<TEntry>['bodyStyle'];
  /** Padding y-axis of the cells */
  cellPy: TTableProps<TEntry>['cellPy'];
  /** The padding size for the body rows of the table.  */
  bodySpace: TTableProps<TEntry>['bodySpace'];
  /** Whether to display the header row. */
  displayHeader: TTableProps<TEntry>['displayHeader'];
  /** The configuration of columns in the table. */
  columns: TTableProps<TEntry>['columns'];
  /** Whether the cell is displayed as a row or column in smaller screens */
  responsiveCellLayout: TTableProps<TEntry>['responsiveCellLayout'];
  highlightVariant: TTableProps<TEntry>['highlightVariant'];
  /** Data row key*/
  rowKey: TTableProps<TEntry>['rowKey'];
  /** Data entry to be displayed in row */
  entry: TEntry;
  /** Whether the table is loading */
  loading?: boolean;
  /** Whether the row is highlighted */
  highlighted?: boolean;
  /** Callback to triggered when a row is clicked */
  onClick?: () => void;
  /** Whether to fix first column in position */
  fixFirstColumn?: boolean;
};

/**
 * Row of the Table component
 */
const TableRow = <TEntry,>({
  bodyStyle,
  cellPy,
  bodySpace,
  displayHeader,
  columns,
  responsiveCellLayout,
  entry,
  loading,
  highlighted,
  highlightVariant = 'bordered',
  onClick,
  rowKey,
  fixFirstColumn,
}: Props<TEntry>) => {
  const getRowKey = (columnIndex: number): string => {
    const col = columns[columnIndex];
    if (rowKey) {
      return `row-${entry?.[rowKey as keyof TEntry]}-${columnIndex}`;
    }
    return `row-${col.title?.toString()}-${columnIndex}`;
  };

  return (
    <div
      className={clsx('tw-table-body-row', {
        [`highlighted-${highlightVariant}`]: highlighted,
        'cursor-pointer hover:tw-bg-neutral-300': onClick,
        'group/tablerow': fixFirstColumn,
      })}
      onClick={onClick}
    >
      {columns.map(({ cell, field, title, style, skeleton, align }, columnIndex) => (
        <div
          key={getRowKey(columnIndex)}
          className={clsx('tw-table-body-cell', {
            [`mt-${bodySpace}`]: bodySpace,
            [`py-${cellPy}`]: cellPy,
            [`py-${bodyStyle?.py}`]: bodyStyle?.py,
            [`px-${bodyStyle?.px}`]: bodyStyle?.px,
            [`tw-text-${align}`]: align,
            [`tw-align-middle`]: !style?.(entry).verticalAlign,
            [`tw-w-${style?.(entry).width}`]: style?.(entry).width,
            [`tw-align-${style?.(entry).verticalAlign}`]: style?.(entry).verticalAlign,
            [`cell-responsive-column-layout`]: responsiveCellLayout === 'column',
            'group-hover/tablerow:tw-bg-neutral-300': fixFirstColumn && columnIndex === 0 && onClick,
            'sticky left-0 tw-bg-theme-wn z-10 fixed-table-cell': fixFirstColumn && columnIndex === 0,
            [`highlighted-${highlightVariant}`]: highlighted,
          })}
        >
          <Display show={displayHeader}>
            <div className="tw-table-body-header-cell">{title}</div>
          </Display>

          <Display show={loading}>{skeleton}</Display>

          {!loading && <>{cell ? cell(entry) : <>{entry[field]}</>}</>}
        </div>
      ))}
    </div>
  );
};

TableRow.displayName = 'TableRow';

export default TableRow;
