import React, { Fragment, memo, ReactNode, useEffect } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { clsx } from 'clsx';

import ModalBody from './body';
import ModalFooter from './footer';
import ModalHead from './head';
import PopupCloseButton from './popupCloseButton';
import ModalTitle from './title';
import { Display } from '../display';
import { BaseComponentType, BreakpointType, ModalSizes, PaddingSizes, ResponsiveReturnType } from '../types';

export interface IModal extends BaseComponentType {
  /**
   * The content to be displayed within the modal body.
   * Only use when modal is not compound.
   */
  children?: ReactNode | React.ReactElement;
  /**
   * The content to be displayed within the modal footer.
   * Only use when modal is not compound.
   */
  footer?: ReactNode | React.ReactElement;
  /** Callback function to be called when the modal is closed. */
  onClose?: (val: boolean) => void;
  /** Callback function to be called when the previous button is clicked. */
  onPreviousClick?: (initial?: boolean) => void;
  /** Whether the modal has a previous button. */
  hasPreviousButton?: boolean;
  /** Whether the modal is open. */
  open?: boolean;
  /** The title of the modal. */
  title?: ReactNode | string | null;
  /** Whether the modal has a title separator */
  titleSeparator?: boolean;
  /** The padding of the modal title. */
  titlePadding?: PaddingSizes | BreakpointType<PaddingSizes>;
  /** Whether the modal is closable. */
  closable?: boolean;
  /** Whether the modal has an overlay. */
  overlay?: boolean;
  /** Whether the modal is closable by clicking overlay. */
  overlayClosable?: boolean;
  /** Whether the modal overlay should have visible color. */
  showOverlay?: boolean;
  /** The padding of the modal body. */
  padding?: PaddingSizes | BreakpointType<PaddingSizes>;
  /** The padding of the modal footer. */
  footerPadding?: PaddingSizes;
  /** The size of the modal. */
  size?: ModalSizes;
  /** Whether the modal can be closed with keyboard shortcuts. */
  enableShortcuts?: boolean;
  /** Custom close button for the modal */
  closeButton?: ReactNode;
  /** Whether the modal is responsive. */
  responsive?: boolean;
  /** Whether the modal has a limit max height. */
  limitMaxHeight?: boolean;
  /** Whether the modal is compound. */
  compound?: boolean;
  /** Background color of the modal. */
  color?: 'theme-wn' | 'theme-wb' | 'white';
  /** CSS style properties of the modal */
  style?: React.CSSProperties;
  /** Variant of the modal */
  variant?: 'default' | 'popup';
}

/**
 * The `Modal` component is used to render a customizable modal dialog.
 *
 * @example
 * <Modal
 *   open={open} title="Modal Title" footer={<div>Footer</div>}
 *   closable
 *   overlayClosable
 *   onClose={(val) => setOpen(val)}
 *   color="theme-wb"
 * >
 *  Modal body
 * </Modal>
 *
 */
const Modal = memo(
  ({
    onClose,
    children,
    onPreviousClick,
    hasPreviousButton,
    open,
    title,
    closable = true,
    padding = { xs: 'lg', sm: 'lg', md: '2xl' },
    footerPadding = '2xl',
    footer,
    size = 'lg',
    enableShortcuts = false,
    titleSeparator = true,
    titlePadding = { xs: 'lg', sm: 'lg', md: '2xl' },
    closeButton,
    overlayClosable = true,
    showOverlay = true,
    responsive = true,
    limitMaxHeight = false,
    compound,
    color = 'theme-wn',
    testId,
    style,
    variant = 'default',
  }: IModal) => {
    function closeModal() {
      onClose?.(false);
      onPreviousClick?.(true);
    }

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (!enableShortcuts) {
          return;
        }

        if (
          (event.key === 'Escape' || event.key === 'Esc') &&
          !event.shiftKey &&
          !event.altKey &&
          !event.ctrlKey &&
          !event.metaKey
        ) {
          onClose?.(false);
        } else if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
          onClose?.(true);
        }
      };

      if (enableShortcuts) {
        document.addEventListener('keydown', handleKeyDown);
      }
      return () => {
        if (enableShortcuts) {
          document.removeEventListener('keydown', handleKeyDown);
        }
      };
    }, [enableShortcuts]);

    const classes = clsx('modal-container tw-drop-shadow-xl z-20', `tw-bg-${color}`, {
      [`modal-size-${size}`]: size,
      'modal-responsive': responsive,
      'limit-max-height': limitMaxHeight,
    });

    return (
      <>
        <Transition show={open} as={Fragment}>
          <Dialog
            autoFocus
            as="div"
            open={open}
            className="dialog"
            onClose={() => overlayClosable && closeModal()}
            data-testid={testId}
          >
            <div className="dialog-content flex flex-col">
              <Transition.Child
                as={Fragment}
                unmount={open}
                enter="ease-in duration-600"
                enterFrom="opacity-0"
                enterTo={showOverlay ? 'opacity-50' : 'opacity-0'}
                leave="ease-out duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className={`dialog-overlay ${showOverlay ? 'opacity-50' : 'opacity-0'}`} />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="ease-in duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-out duration-50"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className={classes} style={style}>
                  <Display show={variant === 'default'}>
                    <ModalHead
                      closable={closable}
                      closeButton={closeButton}
                      closeModal={closeModal}
                      hasPreviousButton={hasPreviousButton}
                      onPreviousClick={onPreviousClick}
                      title={title}
                      titlePadding={titlePadding}
                      titleSeparator={titleSeparator}
                    />
                  </Display>

                  {compound ? children : <ModalBody padding={padding}>{children}</ModalBody>}
                  {footer && <ModalFooter padding={footerPadding}>{footer}</ModalFooter>}
                </div>
              </Transition.Child>
              <Display show={variant === 'popup' && closable}>
                <PopupCloseButton closeModal={closeModal} color={color} responsive={responsive} />
              </Display>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  },
);

Modal.displayName = 'Modal';

export default Object.assign(Modal, { Body: ModalBody, Footer: ModalFooter, Title: ModalTitle });
