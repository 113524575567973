import React, { ComponentProps } from 'react';

import { clsx } from 'clsx';
import QRCode from 'qrcode.react';

import { BaseComponentType, MarginSizes } from '../types';

export type QrProps = BaseComponentType & {
  /** Value used to generate the QR code */
  value?: string;
  /** Size of the QR code in pixels. Default is 128 */
  size?: number;
  /** Level of error correction, 'L', 'M', 'Q' or 'H' from lowest to highest. Default is 'L' */
  level?: string;
  /** viewBox of the QR code */
  viewBox?: string | undefined;
  /** Margin bottom of the QR code */
  mb?: MarginSizes;
  /** Image settings for the QR code */
  imageSettings?: ComponentProps<typeof QRCode>['imageSettings'] & { rounded?: boolean };
  /** Theme of the QR code */
  theme?: 'light' | 'dark';
  /** @deprecated Whether to round the image or not */
  roundImage?: boolean;
};

/**
 * Qr component generates a QR code based on the provided value.
 *
 * @example
 * <Qr value="https://example.com" size={128} level="L" theme="light" />
 */
const Qr = ({ value, size, level, mb, imageSettings, theme = 'light', roundImage, testId }: QrProps) => {
  const classes = clsx('qr-code', {
    [`mb-${mb}`]: mb,
    'round-image': imageSettings?.rounded,
  });

  const colors = {
    dark: {
      bgColor: '#191F1B',
      fgColor: '#ffffff',
    },
    light: {
      bgColor: '#ffffff',
      fgColor: '#000000',
    },
  } as const;

  if (!value) {
    return null;
  }

  return (
    <div className={classes} data-testid={testId ?? 'qr'}>
      <QRCode
        renderAs="svg"
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={value}
        size={size}
        level={level}
        imageSettings={imageSettings}
        {...colors[theme]}
      />
    </div>
  );
};

Qr.displayName = 'Qr';

export default Qr;
