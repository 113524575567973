import React from 'react';

import { Block, Breadcrumbs, Empty, Flex, Image, Link, List, Panel, Text } from '@bilira-org/design';
import { parseAssetPair } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import BlogQuery from '@Libs/clientInstances/blogQuery';

require('dayjs/locale/tr');

type Props = {
  assetPair?: string;
};

const RelatedPosts = ({ assetPair }: Props) => {
  const { i18n, t } = useTranslation();
  const [symbol] = parseAssetPair(assetPair);

  const { data: { records: data = [] } = {} } = BlogQuery.useGetRelatedPosts({
    locale: i18n.language,
    tag: symbol,
    limit: 3,
    offset: 0,
  });

  const { data: coinInfo } = BlogQuery.useGetCoinInfo({ symbol: symbol, locale: i18n.language });

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Panel border="neutral-400">
      <Block gap="sm">
        <Text heading>{t('for-you.related-post-more-info', { name: coinInfo?.name, symbol: symbol })}</Text>

        <List
          empty={<Empty message={t('common.empty-message')} />}
          dataSource={data}
          space="none"
          divider
          renderItem={(item) => (
            <List.Item key={item.slug} px="none" py="2xl">
              <Link width="size-full" anchor href={`${t('urls.blog-prefix')}${item.slug}`} target="_blank">
                <Flex
                  direction={{ xs: 'col', sm: 'row' }}
                  gap="md"
                  items={{ xs: 'start', sm: 'center' }}
                  justify="between"
                  width="full"
                >
                  <div>
                    <Text weight="semibold" color="secondary-500" mb="sm">
                      {item.name}
                    </Text>
                    <Text mb="lg">{item.sub_content}</Text>
                    <Breadcrumbs listType="disc">
                      <Breadcrumbs.Item>
                        <Text size="sm">{item.category_name}</Text>
                      </Breadcrumbs.Item>
                      <Breadcrumbs.Item>
                        <Text size="sm">{dayjs(item.create_date, { locale: i18n.language }).format('DD MMM')}</Text>
                      </Breadcrumbs.Item>
                      <Breadcrumbs.Item>
                        <Text size="sm" color="secondary-500">
                          {item.tag_name}
                        </Text>
                      </Breadcrumbs.Item>
                    </Breadcrumbs>
                  </div>
                  <Image
                    style={{ width: '240px' }}
                    src={item.image}
                    alt={item.name}
                    objectFit="fill"
                    rounded="lg"
                    skeletonAspectRatio={2}
                  />
                </Flex>
              </Link>
            </List.Item>
          )}
        />
      </Block>
    </Panel>
  );
};

export default RelatedPosts;
