import React from 'react';

import { Rank } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';

import useManageWatchlist from '@/libs/hooks/useManageWatchList';

type Props = {
  baseAsset?: CoinSymbolType;
  quoteAsset?: CoinSymbolType;
};
const AddWatchlistStar = ({ baseAsset, quoteAsset }: Props) => {
  const { toggleWatchlist, isInWatchlist, isPending } = useManageWatchlist({ baseAsset, quoteAsset });

  if (!(baseAsset && quoteAsset)) {
    return null;
  }

  return <Rank variant="rating" shape="star" ranked={isInWatchlist} onClick={toggleWatchlist} disabled={isPending} />;
};

export default AddWatchlistStar;
