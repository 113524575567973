import React, { memo } from 'react';

import { clsx } from 'clsx';

import { Block, BlockProps } from '../block';
import {
  BaseComponentType,
  BreakpointType,
  CustomWidthType,
  DirectionType,
  FlexItemsType,
  FlexType,
  FlexWrapType,
  GapSizes,
  JustifyType,
  MarginSizes,
  PaddingSizes,
  ResponsiveReturnType,
} from '../types';
import { buildResponsiveClass } from '../utils';

export interface FlexProps
  extends Omit<BlockProps, 'direction' | 'justify' | 'items' | 'row' | 'gap' | 'height' | 'width' | 'wrap' | 'mb'>,
    BaseComponentType {
  /** Flex direction */
  direction?: DirectionType | BreakpointType<DirectionType>;
  /** Flex type */
  flexType?: FlexType | BreakpointType<FlexType>;
  /** Justify content of the flex items */
  justify?: JustifyType | BreakpointType<JustifyType>;
  /** Alignment of the flex items */
  items?: FlexItemsType | BreakpointType<FlexItemsType>;
  /** Gap between the flex items */
  gap?: GapSizes | BreakpointType<GapSizes>;
  /** Whether to use flexbox or not */
  flex?: boolean;
  /** Width of the flex container  */
  width?: BreakpointType<CustomWidthType> | CustomWidthType;
  /** Margin y-axis */
  my?: MarginSizes;
  /** Margin x-axis */
  mx?: MarginSizes;
  /** Padding y-axis */
  py?: PaddingSizes;
  /** Padding x-axis */
  px?: PaddingSizes;
  /** Margin top */
  mt?: MarginSizes;
  /** Margin bottom */
  mb?: BreakpointType<MarginSizes> | MarginSizes;
  /** Margin left */
  ml?: BreakpointType<MarginSizes> | MarginSizes;
  /** Whether to wrap the flex items */
  wrap?: boolean | FlexWrapType | BreakpointType<FlexWrapType>;
  /** Whether to flex-grow */
  grow?: '1' | '0';
  /** Whether to flex-shrink */
  shrink?: '1' | '0';
}

/**
 * Flex component for creating flexible layouts using the flexbox model.
 *
 * @example
 * // Example usage of Flex component
 * <Flex direction="row" justify="center" items="center" gap="4" testId="my-flex">
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Flex>
 */
const Flex = memo(
  ({
    direction,
    flex = true,
    flexType,
    justify,
    items,
    width,
    className,
    grow,
    shrink,
    my,
    mx,
    py,
    px,
    mt,
    mb,
    ml,
    gap,
    wrap,
    testId,
    ...props
  }: FlexProps) => {
    let directionClass: ResponsiveReturnType = {};
    let contentClasses: ResponsiveReturnType = {};
    let flexTypeClass: ResponsiveReturnType = {};
    let justifyClass: ResponsiveReturnType = {};
    let itemClass: ResponsiveReturnType = {};
    let gapClass: ResponsiveReturnType = {};
    let wrapClass: ResponsiveReturnType = {};
    let mbClass: ResponsiveReturnType = {};
    let mlClass: ResponsiveReturnType = {};

    if (direction) {
      directionClass = buildResponsiveClass(direction, 'tw-flex-');
    }

    if (width) {
      contentClasses = buildResponsiveClass(width, 'tw-custom-width-');
    }

    if (flexType) {
      flexTypeClass = buildResponsiveClass(flexType, 'tw-flex-');
    }

    if (justify) {
      justifyClass = buildResponsiveClass(justify, 'tw-justify-');
    }

    if (items) {
      itemClass = buildResponsiveClass(items, 'tw-items-');
    }

    if (gap) {
      gapClass = buildResponsiveClass(gap, 'gap-');
    }

    if (wrap) {
      // For backward compability
      if (wrap === true) {
        wrapClass = { 'tw-flex-wrap': true };
      } else {
        wrapClass = buildResponsiveClass(wrap, 'tw-flex-');
      }
    }

    if (mb) {
      mbClass = buildResponsiveClass(mb, 'mb-');
    }

    if (ml) {
      mlClass = buildResponsiveClass(ml, 'ml-');
    }

    const classes = clsx(
      {
        'flex tw-flex-col': flex,
        [`my-${my}`]: my,
        [`mx-${mx}`]: mx,
        [`py-${py}`]: py,
        [`px-${px}`]: px,
        [`mt-${mt}`]: mt,
        [`tw-grow-${grow}`]: grow,
        [`tw-shrink-${shrink}`]: shrink,
      },
      gapClass,
      directionClass,
      flexTypeClass,
      contentClasses,
      justifyClass,
      itemClass,
      wrapClass,
      mbClass,
      mlClass,
      className,
    );

    return (
      <Block flex={false} className={classes} testId={testId ?? 'flex'} {...props}>
        {props.children}
      </Block>
    );
  },
);

/**
 * Flex item component to be used as a child of the Flex component.
 *
 * @example
 * // Example usage of Flex.Item component
 * <Flex>
 *   <Flex.Item shrink="0" grow="1">
 *     <div>Item content</div>
 *   </Flex.Item>
 * </Flex>
 */

const Item = ({ flex = false, testId, ...props }: FlexProps) => {
  return (
    <Flex flex={flex} testId={testId ?? 'flex-item'} {...props}>
      {props.children}
    </Flex>
  );
};

Flex.displayName = 'Flex';

export default Object.assign(Flex, { Item });
