import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactNode, Ref } from 'react';

import { clsx } from 'clsx';

import MenuItem, { MenuItemProps as ItemProps } from './menuItem';
import { BaseComponentType } from '../types';

export type MenuItemProps = ItemProps;

export type MenuProps<T> = HTMLAttributes<HTMLUListElement> &
  BaseComponentType & {
    data?: T[];
    /** The children to be rendered inside the menu. */
    children?: React.ReactNode;
    /** A function to render each item in the data array. */
    renderItem?: (data: T, index: number) => JSX.Element | JSX.Element[];
    /** A ref to attach to the underlying `ul` element. */
    mRef?: Ref<HTMLUListElement>;
  };

/**
 * The `Menu` component is used to create a menu with optional data-driven rendering of menu items.
 *
 * @example
 * const data = [
 *   {
 *     id: 1,
 *     name: 'Item 1',
 *   },
 *   {
 *     id: 2,
 *     name: 'Item 2',
 *   },
 *   {
 *     id: 3,
 *     name: 'Item 3',
 *   },
 * ];
 *
 * <Menu data={data} renderItem={(item) => <Menu.Item key={item.id}>{item.name}</Menu.Item>} />
 */
const Menu = <T,>(
  { data, renderItem, className, children, testId, ...props }: MenuProps<T>,
  ref: ForwardedRef<HTMLUListElement>,
) => {
  const classes = clsx('menu snap-x', 'menu-normal', className, {});

  if (data && renderItem) {
    return (
      <ul role="menu" className={classes} {...props} ref={ref} data-testid={testId ?? 'menu'}>
        {data.map(renderItem)}
        {children}
      </ul>
    );
  }

  return (
    <ul role="menu" className={classes} {...props} ref={ref} data-testid={testId ?? 'menu'}>
      {children}
    </ul>
  );
};

/**
 * The `MenuWithRef` component is a forward-ref wrapper for the `Menu` component.
 *
 * The `Menu` component is used to create a menu with optional data-driven rendering of menu items.
 */
export const MenuWithRef = forwardRef(Menu) as <T>(
  props: MenuProps<T> & { ref?: ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof Menu>;

Menu.displayName = 'Menu';

export default Object.assign(MenuWithRef, { Item: MenuItem });
