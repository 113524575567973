import { useMemo } from 'react';

import { validateKycCryptoWithdraw } from '@bilira-org/react-utils';

import useAuth from '@/libs/hooks/userAuth';
import useUserInfo from '@Libs/hooks/useUserInfo';

const useWithdrawalValidation = () => {
  const { kycLevel } = useUserInfo();
  const { bankVerified } = useAuth();

  const { error } = useMemo(
    () =>
      validateKycCryptoWithdraw({
        kycLevel: kycLevel,
        bankVerified,
        errorMessages: {
          kyc2AndBankNotVerified: 'crypto.withdraw-not-allowed.kyc2-and-bank',
          kyc2NotVerified: 'crypto.withdraw-not-allowed.kyc2',
          bankNotVerified: 'crypto.withdraw-not-allowed.bank',
        },
      }),
    [kycLevel, bankVerified],
  );

  return error;
};

export default useWithdrawalValidation;
