import React, { forwardRef, HTMLAttributes, ReactNode, useMemo } from 'react';

import { clsx } from 'clsx';

import {
  AlignContentType,
  BaseComponentType,
  BaseSize,
  BreakpointType,
  GapSizes,
  JustifyItemsType,
  MarginSizes,
  PlaceItemsType,
  ResponsiveReturnType,
} from '../types';
import { buildResponsiveClass } from '../utils';

export interface IGrid extends HTMLAttributes<HTMLElement>, BaseComponentType {
  /** Gap between grid items. */
  gap?: GapSizes;
  /** Content of the grid. */
  children?: ReactNode;
  /** Columns of the grid. */
  col?: BreakpointType<number> | number | undefined;
  /** Justification of grid items. */
  justifyItems?: JustifyItemsType;
  /** Placement of grid items. */
  placeItems?: BreakpointType<PlaceItemsType> | PlaceItemsType;
  /** Alignment of grid content. */
  alignContent?: AlignContentType;
  /** Minimum height of the grid. */
  minHeight?: BaseSize;
  /** Margin top size */
  mt?: MarginSizes;
}

type ReturnType = { [key: string]: boolean };
const aggregateResponsiveClass = (col: BreakpointType<number> | number): string | ReturnType => {
  if (typeof col === 'number') {
    return `grid grid-column-${col}`;
  }

  return Object.entries(col).reduce<ReturnType>((agg, [key, value]) => {
    return { ...agg, grid: true, [`${key}:grid-column-${value}`]: true };
  }, {});
};

/**
 * Grid component for creating responsive grid layouts.
 *
 * @example
 * <Grid col={{ xs: 1, sm: 2, md: 3, lg: 4 }} gap="md" justifyItems="center" placeItems="stretch" alignContent="start" minHeight="20rem" testId="responsive-grid">
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 * </Grid>
 */
export const Grid = forwardRef<HTMLDivElement, IGrid>(
  (
    {
      children,
      col = { xs: 1, sm: 1, md: 1, lg: 1 },
      gap = 'md',
      justifyItems,
      placeItems,
      alignContent,
      className,
      minHeight,
      testId,
      mt,
      ...props
    },
    ref,
  ) => {
    const gridClass = useMemo(() => aggregateResponsiveClass(col), [col]);

    let placeItemsClass: ResponsiveReturnType = {};

    if (placeItemsClass) {
      placeItemsClass = buildResponsiveClass(placeItems, 'tw-place-items-');
    }

    return (
      <div
        className={clsx(gridClass, className, placeItemsClass, {
          [`tw-justify-items-${justifyItems}`]: justifyItems,
          [`tw-content-${alignContent}`]: alignContent,
          [`gap-${gap}`]: gap,
          [`tw-min-h-${minHeight}`]: minHeight,
          [`mt-${mt}`]: mt,
        })}
        data-testid={testId ?? 'grid'}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  },
);
