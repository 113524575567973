import React from 'react';

import kycQuery from '@Libs/clientInstances/idpQuery';

import Kyc1Panel from './components/Kyc1Panel';
import Kyc2Panel from './components/Kyc2Panel';
import Kyc3Panel from './components/Kyc3Panel';
import KycCorporatePanel from './components/KycCorporatePanel';
import { LEGAL_STATUSES } from '../constants';

function Cards() {
  const { data } = kycQuery.useGetUserKyc();
  const isCorporate: boolean = data?.legal_status === LEGAL_STATUSES.corporate;

  if (isCorporate) {
    return <KycCorporatePanel />;
  }

  return (
    <>
      <Kyc1Panel />
      <Kyc2Panel />
      <Kyc3Panel />
    </>
  );
}

export default Cards;
