import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import { ResultType } from '../../model';
import { IHealthApi } from './interface';

class HealthApi extends HttpClient implements IHealthApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getHealth = async () => await this.instance.get<HttpErrorType, ResultType>('/healthz');
}

export default HealthApi;
