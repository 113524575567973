import { memo } from 'react';

import { Layout, useScrollRestoration } from '@bilira-org/design';
import { composeRef } from '@bilira-org/design/src/components/ref';
import { Outlet } from 'react-router-dom';

import useTrackHeaderHeight from '@/libs/hooks/useTrackHeaderHeight';
import RewardsRegistrationModal from '@/modules/rewards/components/RewardsRegistrationModal';
import { PreferencesInitializer, RemoteConfigInitializer } from '@Components/initializers';
import AnnouncementBar from '@Components/layouts/components/components/AnnouncementBar';
import LanguagePreferenceModal from '@Components/layouts/components/components/modals/LanguagePreferenceModal';
import NewFeatureModal from '@Components/layouts/components/components/modals/NewFeatureModal';
import NotificationModal from '@Components/layouts/components/components/modals/notification/NotificationModal';
import UserEligibleToV2Modal from '@Components/layouts/components/components/modals/UserEligibleToV2Modal';
import PrivateMobilMenuDrawer from '@Components/layouts/components/PrivateMobilMenuDrawer';
import PublicMobilMenuDrawer from '@Components/layouts/components/PublicMobilMenuDrawer';
import Sidebar from '@Components/layouts/components/Sidebar';
import TradeModal from '@Components/trade/TradeModal';
import { useRegisterScrollEnd } from '@Libs/hooks/useScrollEvents';

import LegalDocuments from './components/components/LegalDocuments';
import AnnouncementPopupModal from './components/components/modals/AnnouncementPopupModal';
import PublicCookieModal from './components/components/modals/PublicCookieModal';
import Header from './components/Header';
import OnboardingTour from './components/OnboardingTour';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';

const { Head, Main, Container } = Layout;

function DefaultLayout() {
  const mainRef = useScrollRestoration();
  const { ref: headerRef } = useTrackHeaderHeight<HTMLDivElement>('header');
  const scrollRef = useRegisterScrollEnd({ eventName: 'reached-content-scroll-bottom' });

  return (
    <ErrorBoundary>
      <Layout>
        <OnboardingTour />
        <AnnouncementBar />
        <PrivateMobilMenuDrawer />
        <PublicMobilMenuDrawer />
        <NewFeatureModal />
        <LanguagePreferenceModal />
        <UserEligibleToV2Modal />
        <TradeModal />
        <PublicCookieModal />
        <LegalDocuments />
        <AnnouncementPopupModal />
        <NotificationModal />
        <RewardsRegistrationModal />

        <PreferencesInitializer />
        <RemoteConfigInitializer />

        <Head ref={headerRef}>
          <Header />
        </Head>
        <Container>
          <Sidebar />
          <Main ref={composeRef(mainRef, scrollRef)}>
            {/* Replacing your content */}
            <Outlet />
            {/* /End replace */}
          </Main>
        </Container>
      </Layout>
    </ErrorBoundary>
  );
}

export default memo(DefaultLayout);
