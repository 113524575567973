import React, {
  Fragment,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { autoUpdate, flip, FloatingPortal, size as sizeMiddleware, useFloating } from '@floating-ui/react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';

import { Block } from '../block';
import { Display } from '../display';
import { Icon } from '../icons';
import Input from '../input/input';
import {
  BackgroundColorType,
  BaseComponentType,
  CustomWidthType,
  FontWeight,
  JustifyType,
  PaddingSizes,
  TextColorType,
  TextSizes,
} from '../types';
import { Text, Typography } from '../typography';
import { wrapWithElementIfInvalid } from '../utils';

export type DropdownProps<T> = BaseComponentType & {
  /** Data source for the dropdown options */
  dataSource?: T[];
  /** Currently selected value */
  value: ReactNode | string;
  /** Custom rendering function for each option */
  renderItem?: (item: T, index: number) => ReactElement;
  /** Background color of the dropdown button */
  color?: BackgroundColorType;
  /** The position of the dropdown list relative to the button */
  position?: 'left' | 'right';
  /** Additional classes for dropdown button */
  className?: string;
  /** Whether to show the dropdown arrow */
  appearance?: boolean;
  /** Color of the dropdown arrow */
  arrowColor?: TextColorType;
  /** Text size for the dropdown button */
  textSize?: TextSizes;
  /** Font weight of the dropdown button */
  fontWeight?: FontWeight;
  /** Custom width of the dropdown list */
  listSize?: CustomWidthType | 'size-max';
  /** Title of the dropdown list */
  contentTitle?: React.ReactNode;
  /** Children to be rendered inside the dropdown list for custom option renders.
   * @note Only works when dataSource is not provided.
   */
  children?: ReactNode | ((close: () => void) => ReactNode);
  /** Whether to show a divider between each option */
  divider?: boolean;
  /** Callback function for when an option is selected */
  onSelect?: (item: T) => void;
  /** Padding size of the dropdown button */
  size?: PaddingSizes;
  /** Justify content of the dropdown button */
  justify?: JustifyType;
  // Custom width of the dropdown button
  width?: CustomWidthType;
  /** Callback function for when dropdown menu is closed */
  onClose?: () => void;
  /** Text color of the dropdown button */
  textColor?: TextColorType;
  /** Whether dropdown is disabled */
  disabled?: boolean;
  /** Function to filter items */
  filterItems?: (item: T, search: string) => boolean;
  /** Whether list height should be limited */
  limitListHeight?: boolean;
};

/**
 * Dropdown component for creating a dropdown menu with customizable options.
 *
 * @example
 * // Example usage of Dropdown component
 * <Dropdown
 *   value="Select an option"
 *   dataSource={[{ label: 'Option 1' }, { label: 'Option 2' }, { label: 'Option 3' }]}
 *   onSelect={(selectedItem) => console.log('Selected:', selectedItem)}
 *   testId="my-dropdown"
 * >
 *   <Dropdown.Item>Option 1</Dropdown.Item>
 *   <Dropdown.Item>Option 2</Dropdown.Item>
 *   <Dropdown.Item>Option 3</Dropdown.Item>
 * </Dropdown>
 *
 */
const Dropdown = <T,>({
  value,
  textSize = 'sm',
  fontWeight = 'semibold',
  listSize = '192',
  size = 'none',
  dataSource,
  renderItem,
  color,
  position = 'left',
  contentTitle,
  children,
  appearance = true,
  arrowColor = 'neutral-600',
  onSelect,
  divider,
  testId = 'dropdown',
  justify = 'center',
  width,
  onClose,
  textColor = 'secondary-500',
  disabled,
  filterItems,
  limitListHeight = true,
}: DropdownProps<T>): JSX.Element => {
  const [state, setState] = useState(false);
  const [search, setSearch] = useState('');
  const renderInnerItem = useCallback(
    (item: T, index: number): ReactElement | null => {
      if (!renderItem) return null;

      return wrapWithElementIfInvalid({
        node: renderItem(item, index),
        wrapper: <div></div>,
        props: { onClick: () => onSelect?.(item) },
      });
    },
    [onSelect, renderItem],
  );

  const filteredItems = useMemo(
    () => (filterItems && search !== '' ? dataSource?.filter((item) => filterItems(item, search)) : dataSource),
    [dataSource, filterItems, search],
  );

  const items = useMemo(() => {
    return filteredItems?.map((item: T, index: number) => renderInnerItem(item, index));
  }, [filteredItems, renderInnerItem]);

  const classes = clsx('dropdown', {
    [`p-${size}`]: size,
    [`dropdown-${color}`]: color,
    [`tw-justify-${justify}`]: justify,
  });

  const { refs, floatingStyles } = useFloating({
    placement: position === 'left' ? 'bottom-start' : 'bottom-end',
    whileElementsMounted: autoUpdate,
    transform: false,
    middleware: [
      flip(),
      listSize === 'full'
        ? sizeMiddleware({
            apply({ availableWidth, availableHeight, elements }) {
              const element = elements.reference as HTMLElement;
              Object.assign(elements.floating.style, {
                width: `${element.offsetWidth}px`,
              });
            },
          })
        : undefined,
    ],
  });

  const searchInputRef = useRef<HTMLInputElement>(null);

  return (
    <Menu
      as="div"
      className={clsx('dropdown-menu', {
        [`tw-custom-width-${width}`]: width,
      })}
      data-testid={testId}
    >
      {({ open, close }) => (
        <>
          <Menu.Button
            onClick={() => {
              const newState = !state;
              setState(newState);
              setSearch('');
              setTimeout(() => {
                if (newState && filterItems && searchInputRef.current) {
                  searchInputRef.current.focus();
                }
              }, 150); // To compensate animation duration
            }}
            className={classes}
            disabled={disabled}
            ref={refs.setReference}
          >
            {typeof value === 'string' ? (
              <Text size={textSize} weight={fontWeight} color={textColor}>
                {value}
              </Text>
            ) : (
              value
            )}
            {appearance && (
              <ChevronDownIcon
                className={clsx(`w-6 h-6 ml-2 -mr-1 tw-text-${arrowColor} `, {
                  'hover:text-neutral-400': !disabled,
                })}
              />
            )}
          </Menu.Button>
          <FloatingPortal id="dropdown-portal">
            <Transition
              show={open}
              as={Menu.Items}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              afterLeave={() => {
                onClose?.();
                setSearch('');
              }}
              ref={refs.setFloating}
              style={floatingStyles}
              className="dropdown-menu-list"
            >
              <div
                className={clsx('dropdown-menu-panel', {
                  [`tw-custom-width-${listSize}`]: listSize !== 'size-max',
                  [`tw-w-${listSize}`]: listSize === 'size-max',
                  'max-h-64': limitListHeight,
                })}
                data-testid={`${testId}-panel`}
              >
                {contentTitle && <div className="dropdown-menu-title">{contentTitle}</div>}
                <div
                  className={clsx('dropdown-menu-content', {
                    divider: divider,
                  })}
                >
                  {filterItems && (
                    <Block px="xs" py="sm">
                      <Input
                        ref={searchInputRef}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        p="sm"
                        iconStart={<Icon size="sm" type="o:magnifying-glass" />}
                        iconStartProps={{ custom: true, space: 'sm' }}
                      />
                    </Block>
                  )}
                  <div className="overflow-auto ">
                    {items ?? (typeof children === 'function' ? children(close) : children)}
                  </div>
                </div>
              </div>
            </Transition>
          </FloatingPortal>
        </>
      )}
    </Menu>
  );
};

interface IItem {
  children: ReactNode;
  extra?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
}

function Item({ children, extra, icon, onClick, selected }: IItem) {
  return (
    <div className="dropdown-item-content">
      <Menu.Item>
        {({ active }) => (
          <button
            onClick={onClick}
            className={clsx('dropdown-menu-button', {
              'tw-bg-neutral-200': active,
              'dropdown-menu-button-selected': selected,
            })}
          >
            <div className={clsx('dropdown-menu-item pointer-events-none')}>
              {icon && <div className="dropdown-menu-icon p-2">{icon}</div>}
              {children}
            </div>
            {extra}
            <Display show={selected}>
              <Icon type="o:check" size="xs" className="ml-auto my-auto" color="primary-500" />
            </Display>
          </button>
        )}
      </Menu.Item>
    </div>
  );
}

Dropdown.displayName = 'Dropdown';

export default Object.assign(Dropdown, { Item });
