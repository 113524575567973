import { useEffect, useState } from 'react';

import {
  Block,
  Button,
  Card,
  Empty,
  Flex,
  IconCustom,
  List,
  Meta,
  Panel,
  PanelCopy,
  Popover,
  Separator,
  Text,
  useCopy,
} from '@bilira-org/design';
import { isEmpty, ReferralCodeType, truncateMiddle } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import FormattedPercentage from '@/components/common/FormattedPercentage';
import { getReferralRegisterUrl } from '@/libs/utils';

import { calculateCashbackPercentage, calculatePercentage } from '../helpers';

type Props = {
  defaultCommission?: number;
  referralCodes?: ReferralCodeType[];
  defaultReferralCode?: ReferralCodeType;
};

const ReferralCampaign = ({ defaultCommission, referralCodes, defaultReferralCode }: Props) => {
  const { t } = useTranslation();
  const { onCopy } = useCopy({ copiedNotification: t('common.copied') });

  const [selectedReferralCodeId, setSelectedReferralCodeId] = useState<string | undefined>(defaultReferralCode?.code);

  useEffect(() => {
    // Reset selected referral code if default code changes
    setSelectedReferralCodeId(defaultReferralCode?.code);
  }, [defaultReferralCode?.code]);

  const selectedReferralCode = referralCodes?.find((referralCode) => referralCode.code === selectedReferralCodeId) || {
    title: '',
    code: '',
    value: 0,
    default: false,
  };

  const referralRegisterUrl = selectedReferralCode?.code ? getReferralRegisterUrl(selectedReferralCode.code) : '';
  const truncatedReferralRegisterUrl = referralRegisterUrl
    ? truncateMiddle(referralRegisterUrl, 22, selectedReferralCode.code.length)
    : '';

  return (
    <Panel color="radial-primary" size="4xl">
      <Flex direction={{ xs: 'col', sm: 'col', lg: 'row' }} gap="xl" items="center">
        <Flex.Item width={{ xs: 'full', sm: 'full', lg: '2/4' }}>
          <Text heading size="3xl" weight="bold" mb="lg">
            {t('referral.earn-with-friends')}
          </Text>
          <Text size="lg" color="secondary-500" mb="xl">
            {t('referral.earn-commission-from-friends-transactions', {
              percentage: isEmpty(defaultCommission) ? '-' : calculatePercentage(defaultCommission as number),
            })}
          </Text>
          <IconCustom type="trumpet-phone" />
        </Flex.Item>
        <Flex.Item width={{ xs: 'full', sm: 'full', lg: '2/4' }} flex items="end">
          <Card shadow color="theme-wn" size="none" customWidth={{ xs: 'full', sm: 'full', lg: '450' }}>
            <Card.Header
              py="lg"
              px="2xl"
              title={
                <Popover
                  position="left"
                  button={
                    <Block row items="center" gap="md">
                      <Text color="secondary-500" size="xl" weight="medium">
                        {selectedReferralCode.title}
                      </Text>
                    </Block>
                  }
                  customWidth="384"
                  iconColor="neutral-700"
                >
                  {(close) => (
                    <>
                      <List
                        empty={<Empty message={t('common.empty-message')} />}
                        dataSource={referralCodes}
                        space="xs"
                        renderItem={(record) => (
                          <List.Item
                            key={record.code}
                            px="md"
                            py="md"
                            onClick={() => {
                              setSelectedReferralCodeId(record.code);
                              close();
                            }}
                            hoverBgColor="neutral-300"
                          >
                            <Text color="secondary-500" size="lg" weight="medium">
                              {record.title}
                            </Text>
                          </List.Item>
                        )}
                      />
                    </>
                  )}
                </Popover>
              }
              separator={true}
            />
            <Block p="2xl" gap="lg">
              <div>
                <Text size="sm" mb="sm">
                  {t('referral.referral-code')} {selectedReferralCode.default && `(${t('common.standard')})`}
                </Text>
                <Panel customBorder color="primary-20" size="4xl">
                  <Text size="3xl" color="secondary-500">
                    {selectedReferralCode.code}
                  </Text>
                </Panel>
              </div>

              <div>
                <Text size="sm" mb="sm">
                  {t('referral.referral-url')}
                </Text>
                <PanelCopy
                  panelProps={{ border: 'none', color: 'neutral-200' }}
                  copiedNotification={t('common.copied')}
                  copyValue={referralRegisterUrl}
                >
                  {truncatedReferralRegisterUrl}
                </PanelCopy>
              </div>
              <Block row justify="between" items="center">
                <Meta
                  title={<Text size="sm">{t('referral.you-receive')}</Text>}
                  description={
                    <Text size="lg" color="primary-500">
                      <FormattedPercentage value={calculatePercentage(selectedReferralCode.value)} />
                    </Text>
                  }
                />
                <Separator height="size-7" />
                <Meta
                  title={<Text size="sm">{t('referral.referral-cashback-rate')}</Text>}
                  description={
                    <Text size="lg" color="orange-500">
                      <FormattedPercentage
                        value={calculateCashbackPercentage(defaultCommission || 0, selectedReferralCode.value)}
                      />
                    </Text>
                  }
                />
              </Block>
              <Button size="lg" variant="filled" stretch onClick={() => onCopy({ copyValue: referralRegisterUrl })}>
                {t('referral.invite-now')}
              </Button>
            </Block>
          </Card>
        </Flex.Item>
      </Flex>
    </Panel>
  );
};

export default ReferralCampaign;
