import React, { FC, forwardRef, HTMLAttributes, useEffect } from 'react';

import { clsx } from 'clsx';

import Announcement from './announcement';
import useIosViewPort from '../../hooks/useIosViewport';
import { Panel, PanelProps } from '../panel';
import { BackgroundColorType, BaseComponentType } from '../types';

/**
 * Layout
 */
export type LayoutProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional class name for the Layout component */
    className?: string;
    /** The content to be displayed within the Layout component */
    children: React.ReactNode;
    /** Background color of the Layout component */
    color?: BackgroundColorType;
    /** Variant */
    variant?: 'stablecoin' | 'direct';
  };

/**
 * Layout component for organizing the overall structure of a page.
 */
const Layout: FC<LayoutProps> = ({ children, color, className, testId, variant = 'stablecoin' }: LayoutProps) => {
  useIosViewPort();
  const classes = clsx(`layout group layout-${variant}`, className, {
    [`tw-bg-${color}`]: color,
  });

  return (
    <div className={classes} data-testid={testId ?? 'layout'}>
      {children}
    </div>
  );
};

/**
 * Head
 */
export type HeadProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional class name for the Head component */
    className?: string;
    /** The content to be displayed within the Head component */
    children: React.ReactNode;
    /** Variant */
    variant?: 'stablecoin' | 'direct';
  };

/**
 * Head component for organizing content within the head section of a layout.
 */
const Head = forwardRef<HTMLElement, HeadProps>(({ children, className, testId, variant = 'stablecoin' }, ref) => {
  const classes = clsx(`head head-${variant}`, className);

  return (
    <header ref={ref} className={classes} data-testid={testId ?? 'layout-head'}>
      {children}
    </header>
  );
});

/**
 * Aside
 */
export type AsideProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** The content to be displayed within the Aside component */
    children: React.ReactNode;
    /** Indicates whether the Aside component is responsive */
    responsive?: boolean;
    /** Additional class name for the Aside component */
    className?: string;
    /** The ID for the Aside component */
    id?: string;
  };

/**
 * Aside component for organizing additional side content beside the main content.
 */

const Aside: React.FC<AsideProps> = ({ children, responsive, className, id, testId }: AsideProps) => {
  const classes = clsx('aside', className, {
    'aside-responsive-bottom': responsive,
  });
  return (
    <nav className={classes} id={id} data-testid={testId ?? 'layout-aside'}>
      <div className="aside-content">{children}</div>
    </nav>
  );
};

/**
 * Main
 */
export type MainProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional class name for the Main component */
    className?: string;
    /** The content to be displayed within the Main component */
    children: React.ReactNode;
  };

/**
 * Main component for organizing the main content of a layout.
 */
const Main = React.forwardRef<HTMLDivElement, MainProps>(({ children, className, testId, ...props }, ref) => {
  const classes = clsx('main', className);
  return (
    <div role="main" className={classes} ref={ref} data-testid={testId ?? 'layout-main'} {...props}>
      {children}
    </div>
  );
});

/**
 * Content
 */
export type ContainerProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional class name for the Container component */
    className?: string;
    /** The content to be displayed within the Container component */
    children: React.ReactNode;
  };

/**
 * Container component for wrapping content within a layout.
 */
const Container: React.FC<ContainerProps> = ({ children, className, testId }: ContainerProps) => {
  const classes = clsx('layout-container', className);
  return (
    <div className={classes} data-testid={testId ?? 'layout-container'}>
      {children}
    </div>
  );
};
/**
 * Content
 */
export type ContentProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional class name for the Content component */
    className?: string;
    /** The content to be displayed within the Content component */
    children: React.ReactNode;
    /** Indicates whether overflow should be hidden for the Content component */
    overflowHidden?: boolean;
  };

/**
 * Content component for displaying a content within a layout.
 */
const Content: React.FC<ContentProps> = ({ children, overflowHidden, className, testId, ...props }: ContentProps) => {
  const classes = clsx('content', { 'content-overflow-hidden': overflowHidden }, className);

  return (
    <div className={classes} data-testid={testId ?? 'layout-content'} {...props}>
      {children}
    </div>
  );
};

/**
 * Hero
 */
export type HeroProps = HTMLAttributes<HTMLDivElement> &
  Omit<PanelProps, 'mb'> &
  BaseComponentType & {
    /** The content to be displayed within the Hero component */
    children: React.ReactNode;
    /** Indicates whether horizontal padding should be removed until small screens */
    pxNoneUntilSm?: boolean;
  };

/**
 * Hero component for displaying a hero header within a layout.
 */
const Hero: React.FC<HeroProps> = ({ children, className, pxNoneUntilSm, testId, ...props }: HeroProps) => {
  const classes = clsx('hero', { 'hero-px-none-sm': pxNoneUntilSm }, className);

  return (
    <Panel {...props} py="none" px="none" radius="none" className={classes} testId={testId ?? 'layout-hero'}>
      {children}
    </Panel>
  );
};

Layout.displayName = 'Layout';

export default Object.assign(Layout, { Head, Aside, Content, Main, Hero, Container, Announcement });
