import React from 'react';

import { Block, Hidden, Icon, Layout, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const { Hero } = Layout;

const Header = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <Hero view="strip">
        <Block row justify="between" items="center">
          <Hidden show={{ sm: true, md: true, lg: true, xl: true }}>
            <Text color="white" weight="medium" my="md" onClick={() => navigate('/market')}>
              <Icon type="o:arrow-left" size="md" /> {t('market.all-markets')}
            </Text>
          </Hidden>
          <Text heading size="xl" color="white" weight="bold" my="md">
            {t('market.ranking.tracking-header')}
          </Text>
          <div />
        </Block>
      </Hero>
    </>
  );
};

export default Header;
