import React from 'react';

import { Flex, Icon, Meta, Panel, Separator, Typography } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import DocumentFooter from './components/DocumentFooter';
import DefaultAllowance from '../DefaultAllowance';

const { Text } = Typography;

function KycCorporatePanel() {
  const { t } = useTranslation();

  return (
    <>
      <Panel border="neutral-400">
        <Flex gap="lg" direction={{ md: 'row' }} justify="between" wrap>
          <Flex.Item>
            <Meta
              title={
                <Text heading size="xl" weight="bold">
                  {t('account.corporate-title')}
                </Text>
              }
              description={<DefaultAllowance kycLevel={3} />}
            />
          </Flex.Item>
          <Flex.Item flex items="center" p="lg">
            <Meta
              extra={<Icon size="lg" color="green-500" type="s:check-circle" />}
              space="sm"
              title={
                <Text size="sm" color="secondary-500" weight="regular">
                  {t('common.verified')}
                </Text>
              }
            />
          </Flex.Item>
        </Flex>
        <Separator my="2xl" width="size-full" />
        <DocumentFooter />
      </Panel>
    </>
  );
}

export default KycCorporatePanel;
