import React, { ReactNode, useEffect, useState } from 'react';

import { Listbox } from '@headlessui/react';
import { clsx } from 'clsx';
import { useNavigate } from 'react-router-dom';

import { BaseComponentType } from '../types';

export type MenuType = {
  /** href attribute of the menu item */
  href: string;
  /** content of the menu item */
  text: React.ReactNode;
  /** icon to display for the menu item */
  icon: React.ReactElement;
  /** icon to display for the menu item when it is selected */
  activeIcon: React.ReactElement;
};

export type MenuProps<T extends MenuType> = BaseComponentType & {
  /** Additional CSS class name for styling */
  className?: string;
  /** Data representing items in the menu */
  data: T[];
  /** @deprecated currently not used */
  children?: ReactNode;
  /** Default value for the selected menu item */
  defaultValue?: string;
  /** id attribute of the navbar */
  id?: string;
};

/**
 * The `Navbar` component is used to create a navigation bar with selectable menu items.
 *
 * @example
 * const data = [
 *   {
 *     href: '/home',
 *     text: 'Home',
 *     icon: <Icon type="o:home" size="lg" />,
 *     activeIcon: <Icon type="o:home-filled" size="lg" />,
 *   },
 *   {
 *     href: '/about',
 *     text: 'About',
 *     icon: <Icon type="o:info" size="lg" />,
 *     activeIcon: <Icon type="o:info-filled" size="lg" />,
 *   },
 *   {
 *     href: '/contact',
 *     text: 'Contact',
 *     icon: <Icon type="o:envelope" size="lg" />,
 *     activeIcon: <Icon type="o:envelope-filled" size="lg" />,
 *   },
 * ];
 *
 * <Navbar data={data} />
 */
const Navbar = <T extends MenuType>({ data, className, defaultValue, id, testId }: MenuProps<T>) => {
  const [selectedMenu, setSelectedMenu] = useState<string | undefined>(defaultValue);
  const navigate = useNavigate();
  useEffect(() => {
    const result = data.find((d) => d.href === defaultValue);
    setSelectedMenu(result?.href || '');
  }, [defaultValue, data]);

  const onChange = (href: string) => {
    setSelectedMenu(href);
    navigate(href);
  };

  return (
    <Listbox id={id} as="div" value={selectedMenu} onChange={onChange} data-testid={testId ?? 'navbar'}>
      <Listbox.Options static className="navbar">
        {data.map((menu) => (
          <Listbox.Option
            key={`nav-menu-${menu.href}`}
            value={menu.href}
            className={({ selected }) =>
              clsx(className, `navbar-item group/navbar`, {
                'navbar-item-selected': selected,
              })
            }
            data-testid={`nav-menu-${menu.href}`}
          >
            {({ selected }) => (
              <>
                <span
                  className={clsx('navbar-icon group/navbar', {
                    'navbar-icon-selected': selected,
                  })}
                >
                  {selected ? menu.activeIcon : menu.icon}
                </span>
                <span className="link group/navbar">{menu.text}</span>
              </>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

Navbar.displayName = 'Navbar';

export default Navbar;
